import { css } from '@emotion/css'
import {
  FC,
  ReactNode,
  useState,
  ChangeEventHandler,
  ChangeEvent,
  CSSProperties,
} from 'react'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { uploadFile } from '../utils/uploadFile'
import { FileData } from '../components/gallery/FileForm'
import { useFormContext, useWatch } from 'react-hook-form'
import { FormControl, FormHelperText } from '@mui/material'
import { buildGetErrMsg } from '../utils/buildGetErrMsg'
import useSnackResErr from '../hooks/useSnackResErr'
import { useSnackbar } from 'notistack'
import { FILES_BASE_URL } from '../constants/FILES_BASE_URL'
import { extensions, getContentType } from '../utils/liteMime'
import Audio from './Audio'

type AssetButtonProps =
  | {
      type: 'button'
      onClick?: () => void
    }
  | {
      type: 'input'
      onChange:
        | { action: 'upload'; onUploaded?: (newFile: FileData) => void }
        | ((file: File) => void)
    }

interface FileSrc {
  fileSrc: string
  fileExt: string
}

export interface AssetProps {
  updateButton?: AssetButtonProps
  createButton?: AssetButtonProps
  unsetButton?: {
    type: 'button'
    onClick?: () => void
  }
  value?: FileSrc | FileData
  error?: boolean
  helperText?: string
  assetWrapperStyle?: CSSProperties
  fileKind: 'image' | 'audio' | 'video' | 'all'
}

const getErrMsg = buildGetErrMsg({
  DUP_FILE_LABEL: "File's label is duplicated",
})

export const Asset: FC<AssetProps> = ({
  value,
  createButton,
  updateButton,
  unsetButton,
  error,
  helperText,
  assetWrapperStyle,
  fileKind
}) => {
  const [uploadProgress, setUploadProgress] = useState<number | null>(null)

  const assetSrc = value && 'fileSrc' in value ? value.fileSrc : value?.fileHash
  const isFullUrl = assetSrc ? /^(https?|blob):/.test(assetSrc) : undefined

  const snackResErr = useSnackResErr(getErrMsg)
  const { enqueueSnackbar } = useSnackbar()

  const handleChange = async (
    buttonKind: 'create' | 'update',
    e: ChangeEvent<HTMLInputElement>
  ) => {
    let inputButton: AssetButtonProps
    if (buttonKind === 'create' && createButton?.type === 'input') {
      inputButton = createButton
    } else if (buttonKind === 'update' && updateButton?.type === 'input') {
      inputButton = updateButton
    } else {
      return
    }

    if (!e.target.files) return

    let onUploaded: ((newFile: FileData) => void) | undefined
    if (inputButton.onChange instanceof Function) {
      return inputButton.onChange(e.target.files[0])
    } else {
      onUploaded = inputButton.onChange.onUploaded
    }

    const formData = new FormData()

    Array.from(e.target.files).forEach((file) => {
      formData.set('file', file)
    })

    try {
      const res = await uploadFile({
        formData,
        onUploadProgress: (progress) => {
          setUploadProgress(progress)
        },
      })

      if (onUploaded) onUploaded(res.data)

      enqueueSnackbar({
        message: 'File uploaded successfully',
        variant: 'success',
      })
    } catch (e: any) {
      snackResErr(e)
    } finally {
      setUploadProgress(null)
    }

    // @ts-ignore
    e.target.value = null
  }

  const inputCommonProps = {
    type: 'file',
    style: { display: 'none' },
    accept: Object.entries(extensions)
      .filter(
        ([ext, contentType]) =>
          fileKind === 'all' || contentType.startsWith(`${fileKind}/`)
      )
      .map(([ext]) => `.${ext}`)
      .join(', '),
  }

  let body: ReactNode

  if (typeof uploadProgress === 'number') {
    body = (
      <div className={styles.choseButton}>
        <div>
          <p>Uploading</p>
          <p>{uploadProgress}%</p>
        </div>
      </div>
    )
  } else if (value) {
    const updateIcon = <EditIcon />

    let updateButtonElem: ReactNode
    if (!updateButton) {
      updateButtonElem = null
    } else if (updateButton.type === 'button') {
      updateButtonElem = (
        <button
          type="button"
          className={styles.updateButton}
          onClick={updateButton.onClick}
        >
          {updateIcon}
        </button>
      )
    } else if (updateButton.type === 'input') {
      const { type, ...inputProps } = updateButton

      updateButtonElem = (
        <label className={styles.updateButton}>
          {updateIcon}

          <input
            {...inputCommonProps}
            onChange={(e) => handleChange('update', e)}
          />
        </label>
      )
    }

    const fileType = getContentType(value.fileExt.slice(1))

    body = (
      <>
        {updateButtonElem}

        {unsetButton && (
          <button
            type="button"
            className={styles.unsetButton}
            onClick={unsetButton.onClick}
          >
            <DeleteIcon />
          </button>
        )}

        {fileType && assetSrc ? (
          fileType.startsWith('audio/') ? (
            <div className={styles.audioBox}>
              <Audio
                key={assetSrc}
                src={isFullUrl ? assetSrc : `${FILES_BASE_URL}/${assetSrc}`}
                size='large'
                stopClickPropagation
              />
            </div>
          ) : fileType.startsWith('video/') ? (
            <video className={styles.videoBox} controls>
              <source src={isFullUrl ? assetSrc : `${FILES_BASE_URL}/${assetSrc}`} type="video/mp4" />
            </video>
          ) : (
            <img
              key={assetSrc}
              src={isFullUrl ? assetSrc : `${FILES_BASE_URL}/${assetSrc}`}
              className={styles.assetItem}
            />
          )
        ) : (
          'Unsupported file type'
        )}
      </>
    )
  } else if (createButton) {
    const createIcon = <AddIcon style={{ fontSize: 48 }} />

    if (createButton.type === 'button') {
      body = (
        <button
          type="button"
          className={styles.choseButton}
          onClick={createButton.onClick}
        >
          {createIcon}
        </button>
      )
    } else if (createButton.type === 'input') {
      body = (
        <label className={styles.choseButton}>
          {createIcon}

          <input
            {...inputCommonProps}
            onChange={(e) => handleChange('create', e)}
          />
        </label>
      )
    }
  }

  return (
    <FormControl error={error}>
      <div
        className={styles.assetWrapper}
        style={{
          border: error ? '1px solid #d32f2f' : undefined,
          ...assetWrapperStyle,
        }}
      >
        {body}
      </div>

      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}

const styles = {
  assetWrapper: css({
    position: 'relative',
    width: 200,
    height: 200,
    overflow: 'hidden',
    borderRadius: '0.25rem',
    border: '1px solid #64748b',
    textAlign: 'center',
    // display: 'inline-flex',
    // aligItems: 'center',
    // justifyContent: 'center'
  }),
  assetItem: css({
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'cover',
  }),
  choseButton: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    fontSize: 14,
    color: '#64748b',
    cursor: 'pointer',
    width: '100%',
    height: '100%',
    flexShrink: 0,
    border: 'none',
    borderRadius: '0.25rem',
    overflow: 'hidden',
    margin: 0,
    background: 'transparent',
  }),
  updateButton: css({
    position: 'absolute',
    top: 6,
    left: 6,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#333',
    background: '#ccc',
    borderRadius: 8,
    padding: 4,
    border: 'none',
    cursor: 'pointer',
    zIndex: 4,
  }),
  unsetButton: css({
    position: 'absolute',
    top: 6,
    right: 6,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#d32f2f',
    background: '#ccc',
    borderRadius: 8,
    padding: 4,
    border: 'none',
    cursor: 'pointer',
    zIndex: 4,
  }),
  audioBox: css({
    width: '100%',
    height: '100%',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  videoBox: css({
    width: '100%',
    height: '100%',
  }),
}
