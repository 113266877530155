import { FC, useState, useMemo, useEffect } from 'react'
import { Course } from '../../types/course'
import { Button, Card, CardMedia, Grid, Stack, Typography } from '@mui/material'
import { FILES_BASE_URL } from '../../constants/FILES_BASE_URL'
import { Link, useParams, useSearchParams } from 'react-router-dom'
import { useAppSelector } from '../../store/hooks'
import getInstalledCourses from '../../utils/getInstalledCourses'
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
import { enqueueSnackbar } from 'notistack'
import { useLazyGetCoursesQuery, useUpdateCourseIndexMutation } from '../../store/coursesApi'
import { buildGetErrMsg } from '../../utils/buildGetErrMsg'
import EnterTextField from '../../UI/EnterTextField'
import { useFormContext } from 'react-hook-form'
interface CoursesGridProps {
  courses: Course[]
  to: (courseId: string) => string
  reorderItem: { courseId: string; newIndex: number } | null
  handleReorderEnter: (courseId: string) => (value: string) => void
}

const getErrMsg = buildGetErrMsg()


// ---Comp-----------------------------------------------------------------------
// --------------------------------------------------------------------------
const CoursesGrid: FC<CoursesGridProps> = ({ courses, to, reorderItem, handleReorderEnter }) => {

  const userMe = useAppSelector((state) => state.auth.userMe)


  const urls: string[] = useMemo(() => courses.map(({ id }) => to(id)), [courses])

  const installedCourses = useMemo(() => getInstalledCourses(), [])

  const randomText = useMemo(() => Date.now().toString() + Math.random(), [])


  if (courses.length === 0) {
    return <p>There is no courses yet or this is privet course</p>
  }

  return (
    <Grid container spacing={2} alignItems="stretch" style={{ direction: 'rtl' }}>
      {courses.map((course, idx) => (
        <Grid item xs={12} sm={6} md={6} lg={4} key={course.id} style={{ backgroundColor: course.isPrivate ? 'beige' : 'undefined' }}>

          {(userMe?.role === 'admin' || (userMe?.role === 'worker' && userMe.privateCoursesIds.includes(course.id))) && (
            <EnterTextField
              style={{ flexShrink: 1, width: 70 }}
              value={reorderItem?.courseId === course.id
                ? String(reorderItem.newIndex + 1)
                : String(idx + 1)
              }
              autoComplete="off"
              name={randomText + idx}
              size="small"
              variant="outlined"
              regex={/^([1-9][0-9]*)?$/}
              disabled={!!reorderItem}
              onEnter={handleReorderEnter(course.id)}
            />
          )
          }

          <div style={{ width: '100%' }}>
            <Card
              sx={{
                width: '100%',
                height: '100%',
                display: 'block',
                color: 'inherit',
                textDecoration: 'none',
                paddingTop: 2,
                paddingBottom: 2,
              }}
              component={Link}
              to={`/courses/${course.id}/chapters`}
            >
              <Stack spacing={2}>
                <Typography sx={{ padding: '0 16px' }} variant="h6" component="div">
                  {course.title}

                  {installedCourses.find(c => c.id === course.id) && (
                    <DownloadDoneIcon style={{ color: '#1976d2', verticalAlign: 'middle', marginRight: 8 }} />
                  )}
                </Typography>

                {course.imageFile && (
                  <CardMedia
                    style={{
                      width: '100%',
                      height: 200,
                      objectFit: 'cover',
                    }}
                    image={`${FILES_BASE_URL}/${course.imageFile.fileHash}`}
                  />
                )}

                {course.description.trim().length > 0 && (
                  <Typography variant="body2" color="text.secondary" component="div" sx={{ padding: '0 16px' }}>
                    {course.description}
                  </Typography>
                )}
              </Stack>
            </Card>

            {(userMe?.role === 'admin' ||
              (userMe?.role === 'worker' ||
                (userMe?.role === 'typist' &&
                  userMe.privateCoursesIds.includes(course.id)))) && (
                (() => {
                  return (
                    <Button component={Link} to={urls[idx]} variant="outlined">
                      Update
                    </Button>
                  );
                })()
              )}
          </div>
        </Grid>
      )

      )}

    </Grid>
  )
}

export default CoursesGrid
