import { Button } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useSnackbar } from 'notistack';


interface CopyLinkOrTextProps {
  url: string;
  nameText: string;
  copyWithPrefix?: string;
}

const CopyLinkOrText = ({ url, nameText, copyWithPrefix }: CopyLinkOrTextProps ) => {

  const { enqueueSnackbar } = useSnackbar();

  const handleCopy = () => {
    const textToCopy = copyWithPrefix ? `${copyWithPrefix}${url}` : url;
    navigator.clipboard.writeText(textToCopy).then(
      () => {
        enqueueSnackbar('تم نسخ الرابط بنجاح!', { variant: 'success' });
      },
      (err) => {
        enqueueSnackbar({ message: "فشل في النسخ", variant: 'error' })
      }
    );
  };
  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
        <a href={url} target="_blank" rel="noopener noreferrer" style={{ marginRight: '8px' }}></a>
        <Button
          variant="contained"
          color="primary"
          onClick={handleCopy}
          startIcon={<ContentCopyIcon  style={{marginLeft: '1rem'}}/>}
        >
          {nameText}
        </Button>
      </div>
    </div>
  )
}

export default CopyLinkOrText
