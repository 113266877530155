import { FileData } from '../components/gallery/FileForm'
import { AnswerT, SlideI, foreignEntities, slidesKeys } from '../types/slide'
import { Unit } from '../types/unit'

export type SlideFormValues = SlideI & {
  id?: string
  unit: Unit
  imageFile?: FileData
  audioFile?: FileData
  videoFile?: FileData
}

type SlideToPayload = SlideFormValues

export const getSlidePayload = (slideInput: SlideToPayload): SlideI => {
  const result: Record<string, any> = {}


  if (slideInput.type === 'banner-ad') {
    throw new Error('getSlidePayload cannot be used for auto generated slide')
  }

  const slideKeys = slidesKeys[slideInput.type]

  slideKeys.forEach((propKey) => {
    result[propKey] = slideInput[propKey as keyof SlideToPayload]
  })



  for (const foreignEntity of foreignEntities) {
    if (slideKeys.includes(`${foreignEntity}Id` as any)) {
      result[`${foreignEntity}Id`] = slideInput[foreignEntity]?.id ?? null
    }
  }
  
  if (slideKeys.includes('answers' as any) && 'answers' in slideInput) {
    const answers: AnswerT[] = []

    slideInput.answers.forEach((cardAnswer) => {
      const cardAnswerResult = {...cardAnswer}

      if (cardAnswer.type === 'cardAnswer' || cardAnswer.type === 'label') {
        if ('imageFileId' in cardAnswerResult) {
          cardAnswerResult.imageFileId = cardAnswer.imageFile?.id ?? null as any
          delete cardAnswerResult.imageFile
        }

        if ('audioFileId' in cardAnswerResult) {
          cardAnswerResult.audioFileId = cardAnswer.audioFile?.id ?? null as any
          delete cardAnswerResult.audioFile
        }
      }

      answers.push(cardAnswerResult)
    })

    result.answers = answers
  }

  if (!result.categoryId) result.categoryId = null

  return result as SlideI
}
