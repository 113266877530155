import { Course } from '../types/course'
import { FC } from 'react'
import { AsyncSelectSearchRhf } from '../UI/AsyncSelectSearchRhf'

interface CourseSelectSearchProps {
  
  name: string
  required?: boolean
}

export const CourseSelectSearch: FC<CourseSelectSearchProps> = ({
  name,
  required
}) => {
  return (
    <AsyncSelectSearchRhf
      name={name}
      style={{ flexGrow: 1 }}
      requestPathname="/courses"
      mainInputParam="title"
      noOptionsMessage="No courses match the search"
      processOption={(course: Course) => ({
        label: course.title,
        value: course.id,
      })}
      required={required}
      placeholder="Search Course"
      retainUnmatchedValueWhenClose
    />
  )
}
