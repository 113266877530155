/** @jsxImportSource @emotion/react */
import { FC, Dispatch, SetStateAction, FormEventHandler } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { UserAvatarFv } from '../../types/userAvatar'
import { BgiTextField } from '../../UI/BgiTextField'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { useCreateUserAvatarMutation, useUpdateUserAvatarMutation } from '../../store/userAvatarsApi'
import { buildGetErrMsg } from '../../utils/buildGetErrMsg'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { AssetField } from '../gallery/AssetField'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';


interface UserAvatarFormProps {
  isOpen: boolean
  isSending: boolean
  setIsSending: Dispatch<SetStateAction<boolean>>
  onClose: () => void
  onOpen: () => void
}

const getErrMsg = buildGetErrMsg()

// --------------------------------------------------------------------------
// --------------------------------------------------------------------------
const UserAvatarForm: FC<UserAvatarFormProps> = ({ isSending, setIsSending, isOpen, onOpen, onClose }) => {
  const [createUserAvatar] = useCreateUserAvatarMutation()
  const [updateUserAvatar] = useUpdateUserAvatarMutation();

  // const { t } = useTranslation(['UserAvatar', 'common'])


  const { enqueueSnackbar } = useSnackbar()

  const userAvatarForm = useFormContext<UserAvatarFv>()

  const isUpdateForm = useWatch({ name: 'id', control: userAvatarForm.control })

  const onSend: FormEventHandler = userAvatarForm.handleSubmit(async ({ imageFile, ...data }) => {
    const payload = {
      ...data,
      imageFileId: imageFile.id,
    }
    setIsSending(true)

    if (payload.id) {
      const result = await updateUserAvatar({
        ...payload,
        id: payload.id,
      })

      if ('data' in result) {
        enqueueSnackbar({ message: ('Updated_successfully'), variant: 'success' })
        onClose()
      } else {
        enqueueSnackbar({ message: getErrMsg(result.error), variant: 'error' })
      }
    } else {
      const result = await createUserAvatar(payload)

      if ('data' in result) {
        userAvatarForm.reset()
        enqueueSnackbar({ message: ('Created_successfully'), variant: 'success' })
        onClose()
      } else {
        enqueueSnackbar({ message: getErrMsg(result.error), variant: 'error' })
      }
    }

    setIsSending(false)
  })

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        component="form"
        onSubmit={onSend}
        maxWidth="sm"
        fullWidth

      >
        <DialogTitle id="alert-dialog-title">
          New Avatar
        </DialogTitle>
        <DialogContent>
          <AssetField name="imageFile" fileKind="image" required />

          <BgiTextField
            style={{ marginTop: 24 }}
            label={('Title')}
            name="title"
            fullWidth
            size="small"
            maxLength={255}
            labelDirection='right'
          />
        </DialogContent>
        <DialogActions>
          <Button
            style={{ margin: 24 }}
            variant="contained"
            type="submit"
            disabled={isSending}
          >
            {(isUpdateForm ? 'Update' : 'Create')}
          </Button>

          <Button
            variant="outlined"
            color="error"
            onClick={onClose}
            disabled={isSending}
          >
            {('Cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default UserAvatarForm
