import { Button, Checkbox, FormControlLabel, Paper } from '@mui/material'
import { FC, useState } from 'react'
import { BgiLink } from '../../UI/BgiLink'
import { UserAvatarFv, UserAvatarT } from '../../types/userAvatar'
import { useDeleteUserAvatarMutation, useUpdateUserAvatarMutation } from '../../store/userAvatarsApi'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { FILES_BASE_URL } from '../../constants/FILES_BASE_URL'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { FormProvider, useForm } from 'react-hook-form'
import UserAvatarForm from './UserAvatarForm'
import { useSnackbar } from 'notistack'
import { buildGetErrMsg } from '../../utils/buildGetErrMsg'

interface UserAvatarProps {
  userAvatar: UserAvatarT
}

const getErrMsg = buildGetErrMsg()

const UserAvatar: FC<UserAvatarProps> = ({ userAvatar }) => {
  const userAvatarForm = useForm<UserAvatarFv>({
    defaultValues: userAvatar
  });
  const [isOpen, setIsOpen] = useState(false)
  const [isSending, setIsSending] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [deleteUserAvatar] = useDeleteUserAvatarMutation()

  const { enqueueSnackbar } = useSnackbar()

  const handleDelete = async () => {
    const isConfirmed = window.confirm('Are you sure you want to delete this avatar?')

    if (!isConfirmed) return
    
    const result = await deleteUserAvatar(userAvatar.id)

    setIsDeleting(true);

    if ('data' in result) {
      // Do nothing here => because the invalidatesTags in the userAvatarsApi will take care of the cache
    } else {
      enqueueSnackbar({ message: getErrMsg(result.error), variant: 'error' })
    setIsDeleting(false);
    }
  }

  return (
    <>
      <Paper key={userAvatar.id} elevation={3} style={{ padding: 16 }}>
        <h3 style={{ marginBottom: 12 }}>{userAvatar.title}</h3>
        <img style={{ width: '100%' }} src={`${FILES_BASE_URL}/${userAvatar.imageFile.fileHash}`} alt={userAvatar.title} />
        <div style={{ marginTop: 16, display: 'flex', justifyContent: 'space-between' }}>
          <Button variant='contained' onClick={() => setIsOpen(true)} disabled={isDeleting}>
            <EditIcon />
          </Button>

          <Button color="error" variant='contained' onClick={handleDelete} disabled={isDeleting}>
            <DeleteIcon />
          </Button>
        </div>
      </Paper>

      <FormProvider {...userAvatarForm}>
        <UserAvatarForm
          isSending={isSending}
          setIsSending={setIsSending}
          isOpen={isOpen}
          onOpen={() => setIsOpen(true)}
          onClose={() => setIsOpen(false)}
        />
      </FormProvider>
    </>
  )
}

export default UserAvatar
