import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { UserAvatarT, UserAvatarI } from '../types/userAvatar'
import { apiConfig } from './api'

export const userAvatarsApi = createApi({
  reducerPath: 'userAvatarsApi',
  baseQuery: fetchBaseQuery({
    ...apiConfig,
    baseUrl: `${apiConfig.baseUrl}/user-avatars`,
  }),
  keepUnusedDataFor: 0,
  tagTypes: ['UserAvatar'],
  endpoints: (builder) => ({
    getUserAvatars: builder.query<UserAvatarT[], void>({
      query: () => '',
      providesTags: (result, error, arg) =>
        result
          ? [...result.map(({ id }) => ({ type: 'UserAvatar' as const, id })), 'UserAvatar']
          : ['UserAvatar'],
    }),
    createUserAvatar: builder.mutation<UserAvatarT, UserAvatarI>({
      query: (body) => ({ url: `/`, method: 'POST', body }),
      invalidatesTags: ['UserAvatar'],
    }),
    updateUserAvatar: builder.mutation<UserAvatarT, Partial<UserAvatarI> & Pick<UserAvatarT, 'id'>>({
      query: ({ id, ...body }) => ({ url: `/${id}`, method: 'PATCH', body }),
      invalidatesTags: (result, error, arg) => [{ type: 'UserAvatar', id: arg.id }],
    }),
    deleteUserAvatar: builder.mutation<void, string>({
      query: (userAvatarId) => ({ url: `/${userAvatarId}`, method: 'DELETE' }),
      invalidatesTags: (result, error, arg) => [{ type: 'UserAvatar', id: arg }],
    }),
  }),
})

export const {
  useGetUserAvatarsQuery,
  useCreateUserAvatarMutation,
  useUpdateUserAvatarMutation,
  useDeleteUserAvatarMutation,
} = userAvatarsApi
