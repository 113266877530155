import { Container } from '@mui/system'
import { Link } from 'react-router-dom'
import { css } from '@emotion/css'
import { Button, LinearProgress, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { useGetUsersQuery } from '../../store/usersApi'
import { BgiLink } from '../../UI/BgiLink'
import PageHeader from '../../UI/PageHeader'
import LoadingPage from '../LoadingPage'
import Layout from './Layout'
import { useActivateUserMutation, useGetPendingUsersQuery } from '../../store/pendingUsersApi'
import PendingUserRow from '../pending-user-row/PendingUserRow'
import CopyLinkOrText from '../../UI/CopyLinkOrText'

const PendingUsersPage = () => {
  const { isLoading, error, isFetching, data: pendingUsers } = useGetPendingUsersQuery()

  if (isLoading) return <LoadingPage />

  if (error || !pendingUsers) return <p>Unknown Error occurred</p>;


  const WhatsappMessage =
    `*السلام عليكم،*  
    تم إرسال هذه الرسالة تلقائيًا من منصة فوتون التعليمية.  
    
    لقد قمتم بالتسجيل في المنصة، ولكن لم يتم تأكيد البريد الإلكتروني.  
    قد يكون السبب:  
    - إدخال بريد إلكتروني غير صحيح.  
    - وصول الرسالة إلى صندوق الرسائل العشوائية.  
    
    لتفعيل الحساب، يرجى إرسال رسالة إلى:  
    *info@bgi-photon.com*  
    مع بيانات التسجيل الخاصة بكم والتي سبق وأن قمت بتسجيلها على الموقع والتي كانت: 
      
    
    بعد تصحيح الإيميل، سيتم تفعيل الحساب.  
    في حال نسيان كلمة المرور ــ بعد تفعيل الحساب ــ ، يمكنكم طلب كلمة مرور جديدة عبر الرابط:  
    *https://www.bgi-edu.com/auth/begin-reset-password*

    في حالة لم ترغبوا في الاستمرار في التسجيل يرجى تجاهل الرسالة.

    ملاحظة: 
*يرجى الحذر* : كافة رسائلنا عبر وسائل التواصل لا تطلب من المشتركين أو الذين سوف يشتركون في المنصة أي مبالغ مالية أو معلومات حساسة.

    `


  return (
    <Layout>
      <Container>

        <div style={{ width: '100%', height: 30 }} />

        <PageHeader
          style={{ marginBottom: 24 }}
          title="Users"
          actions={
            <Button
              component={Link}
              to="/data-entry/users/create"
              type="button"
              variant="contained"
            >
              Create User
            </Button>
          }
        />

        {isFetching && (

          <LinearProgress style={{
            position: 'fixed',
            top: 0,
            left: 0,
            height: 4,
            width: '100%',
            zIndex: 5
          }} />

        )}
          <CopyLinkOrText
            url={WhatsappMessage as string}
            nameText="copy whatsapp message"
          />
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 500 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>name</TableCell>
                <TableCell>lastName</TableCell>
                <TableCell>classLevel</TableCell>
                <TableCell>email</TableCell>
                <TableCell>school</TableCell>
                <TableCell>city</TableCell>
                <TableCell>mobile</TableCell>
                <TableCell>whatsapp</TableCell>
                <TableCell>country</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Activate</TableCell>
                <TableCell>Delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pendingUsers.map((pendingUser, index) => (
                <PendingUserRow key={pendingUser.id} pendingUser={pendingUser} index={index} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <div style={{ width: '100%', height: 30 }} />
      </Container>
    </Layout>
  )
}

export default PendingUsersPage
