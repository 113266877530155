import { FC, useState } from 'react'
import { Avatar, Button, DialogTitle } from '@mui/material'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Controller, useFormContext } from 'react-hook-form'
import { useGetUserAvatarsQuery } from '../store/userAvatarsApi';
import { FILES_BASE_URL } from '../constants/FILES_BASE_URL';
import { display } from 'html2canvas/dist/types/css/property-descriptors/display';
import { css } from '@emotion/css';

interface UserAvatarSelectProps {
  name: string
}

const UserAvatarSelect: FC<UserAvatarSelectProps> = ({
  name
}) => {
  const { control } = useFormContext()
  const { isLoading: isGetting, error, data: userAvatars } = useGetUserAvatarsQuery()

  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => (
        <>
          <div className={styles.selectedAvatarWrapper}>
            <Avatar
              sx={{ width: 100, height: 100 }}
              src={value ? `${FILES_BASE_URL}/${value}` : undefined}
              onClick={() => setIsOpen(true)}
            />
          </div>

          <Dialog
            open={isOpen}
            onClose={handleClose}
            maxWidth="sm"
            fullWidth
          >
            <DialogTitle>
              Choose Avatar
            </DialogTitle>
            <DialogContent>
              <div className={styles.avatars}>
                {(isGetting || !userAvatars) ? <p style={{ textAlign: 'center' }}>Loading...</p> : (
                  userAvatars.map((avatar) => (
                    <Button
                      key={avatar.id}
                      onClick={() => {
                        onChange(avatar.imageFile.fileHash)
                        handleClose()
                      }}
                    >
                      <Avatar
                        sx={{ width: 100, height: 100 }}
                        src={`${FILES_BASE_URL}/${avatar.imageFile.fileHash}`}
                        alt={avatar.title}
                      />
                    </Button>
                  ))
                )}
              </div>
            </DialogContent>
            <DialogActions>
              <Button color="error" variant='outlined' onClick={handleClose}>
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    />
  )
}

export default UserAvatarSelect

const styles = {
  selectedAvatarWrapper: css({
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 12
  }),
  avatars: css({
    display: 'grid',
    gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
    gap: 12
  })
}