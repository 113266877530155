import React, { useEffect, useRef, useState } from 'react'
import { io, Socket } from 'socket.io-client';
import { token } from 'stylis';
import PageSlidesView from '../../slides/PageSlidesView';
import { SlidesExposedAPI, SlidesProps } from '../../slides/Slides';
import { useAppDispatch } from '../../../store/hooks';
import { goToSlide } from '../../../store/slides';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { CourseSelectSearch } from '../../CourseSelectSearch';
import { Avatar } from '@mui/material';
import UserAvatarSelect from '../../UserAvatarSelect';
import { Course } from '../../../types/course';

type ChallengeStatus = 'idle' | 'connected' | 'searching' | 'started' | 'disconnected';

interface StartChallengeT {
  course: Course;
  userAvatar: string;
}

const ChallengePage = () => {
  const [status, setStatus] = useState<ChallengeStatus>('idle');
  const dispatch = useAppDispatch()

  const [challengeData, setChallengeData] = useState<any>();
  const [myTotalPoints, setMyTotalPoints] = useState(0);
  const [opponentTotalPoints, setOpponentTotalPoints] = useState(0);
  const [isFinished, setIsFinished] = useState(false);
  const [isNavigating, setIsNavigating] = useState(false);

  const formContext = useForm<StartChallengeT>();

  const { handleSubmit, control } = formContext;

  const { course, userAvatar } = useWatch({ control });

  const onSubmit = handleSubmit((data) => {
    startChallenge();
  });



  const isEffectCalled = useRef(false);
  const socketRef = useRef<Socket>();

  const [timer, setTimer] = useState<number>(-1);
  const timerRef = useRef<NodeJS.Timer>();
  const slidesApi = useRef<SlidesExposedAPI>();

  const startTimer = (nextSlideAtISO: string) => {
    const timeDuration = Math.floor((new Date(nextSlideAtISO).getTime() - Date.now()) / 1000);

    // clear previous timer
    clearInterval(timerRef.current);
    setTimer(timeDuration);

    // start new timer
    timerRef.current = setInterval(() => {
      setTimer((prev) => {
        if (prev === 0) {
          clearInterval(timerRef.current);
          setIsNavigating(true);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  }

  // const userMe

  useEffect(() => {
    if (isEffectCalled.current) return;

    isEffectCalled.current = true;

    const socket = io(process.env.REACT_APP_BACKEND_URL!, {
      auth: {
        token: localStorage.getItem('token'),
      },
      transports: ['websocket'],
      upgrade: false
    });

    socket.on('connect', () => {
      setStatus('connected');
    });

    socket.on('challenge-started', (msg) => {
      const data = JSON.parse(msg);
      dispatch(goToSlide(data.currentSlideIndex));

      setChallengeData(data);
      console.log('challenge-started', data);
      setStatus('started');
      startTimer(data.nextSlideAt);
    });

    socket.on('disconnect', () => {
      setStatus('disconnected');
    });

    socket.on('next-slide-navigated', (data) => {
      const onceNavigated = () => {
        setMyTotalPoints(data.myTotalPoints);
        setOpponentTotalPoints(data.opponentTotalPoints);
        setIsFinished(data.isFinished);
        startTimer(data.nextSlideAt);
        setIsNavigating(false);
      };

      slidesApi.current?.submit(onceNavigated);
      console.log('opponent & me  points', data);
    })

    socketRef.current = socket;

  }, [])

  const handlePreNextSlide: SlidesProps['onPreNextSlide'] = (currentSlideIdx, userAnswer) => {
    socketRef.current!.emit('navigate-next-slide', JSON.stringify({
      currentSlideIdx,
      userAnswer,
      leftTime: timer
    }));

    setIsNavigating(true);
  }

  function formatSeconds(seconds: number) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = remainingSeconds.toString().padStart(2, '0');

    return `${formattedMinutes}:${formattedSeconds}`;
  }

  const startChallenge = () => {
    if (!course) return;

    const socket = socketRef.current!;

    setStatus('searching');
    socket.emit('new-challenge', JSON.stringify({ courseId: course.id, userAvatar }));
  }

  if (challengeData) {
    const { slides, units, filesHashes, stickyAds, bannerAd } = challengeData.challenge.slides;

    const challengerMe = challengeData.challengerMe;
    const challengerOpponent = challengeData.challengerOpponent;

    if (isFinished) {
      return (
        <>

          <div>
            Challenger Me: {challengerMe.name} - Points: {myTotalPoints}
          </div>
          <div>
            Challenger Opponent: {challengerOpponent.name} - Points: {opponentTotalPoints}
          </div>

        </>
      )
    }
    return (
      <>
        <div>
          Timer: {formatSeconds(timer)}
        </div>
        <div>
          Challenger Me: {challengerMe.name} - Points: {myTotalPoints}
        </div>
        <div>
          Challenger Opponent: {challengerOpponent.name} - Points: {opponentTotalPoints}
        </div>


        <PageSlidesView
          dir={challengeData.challenge.course.language === 'ar' ? 'rtl' : 'ltr'}
          slides={slides}
          units={units}
          filesHashes={filesHashes}
          course={challengeData.challenge.course}
          courseChapters={challengeData.challenge.chapters}
          isCurExam
          stickyAds={stickyAds}
          isThereBannerAd={!!bannerAd}
          onPreNextSlide={handlePreNextSlide}
          hidePrevBtn
          isNavigatingNext={isNavigating}
          exposeAPI={api => slidesApi.current = api}
        />
      </>
    )
  }

  if (status === 'idle') return <p>Loading...</p>;

  return (
    <div>

      {status === 'connected' && (
        <form onSubmit={onSubmit}>
          <FormProvider {...formContext}>
            <UserAvatarSelect name="userAvatar" />

            <CourseSelectSearch name="course" required />

          </FormProvider>

          <button disabled={!course} type="submit">Start Challenge</button>
        </form>
      )}


      <div>
        Status: {status}
      </div>
    </div>
  );
}

export default ChallengePage
