import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { axiosInstance } from '../utils/axiosInstance';

export interface UserMe {
  id: string;
  name: string;
  lastName: string;
  role: 'admin' | 'sub-admin' | 'typist' | 'student' | 'teacher' | 'worker';
  privateCoursesIds: string[]
}

export interface LoginPayload {
  email: string;
  password: string;
}

interface AuthState {
  isLoading: boolean;
  isAutoLogging: boolean;
  error: string | null;
  userMe: UserMe | null;
}

interface isTeacher{
  isTeacher: boolean
}
// --------------------------------------------------------------------------------------
export const loginAsync = createAsyncThunk<
  UserMe | null,
  LoginPayload,
  { state: AuthState }
>('auth/login', async (payload, { getState, rejectWithValue }) => {
  const { isLoading } = getState();

  if (isLoading) return;

  try {
    const res = await axiosInstance.post('/auth/login', payload);
    const authResult = res.data;

    window.localStorage.setItem('token', authResult.token);

    await axiosInstance.get('/auth/userMe')

    return authResult.userMe ?? null;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      return rejectWithValue(e.response?.data.errCode);
    } else {
      throw e;
    }
  }
});

export const autoLoginAsync = createAsyncThunk<
  UserMe | null,
  void,
  { state: AuthState }
>('auth/autoLogin', async (payload, { getState }) => {
  const { isAutoLogging } = getState();

  if (isAutoLogging) return;

  const token = localStorage.getItem('token');

  if (!token) return null;

  try {
    const res = await axiosInstance.get('/auth/userMe');
    const userMe = res.data ?? null

    if (!userMe) {
      localStorage.removeItem('token')
    }

    return userMe ;
  } catch (e) {
    return null;
  }
});
// ----------------------------------------------------------------------------

const initialState: AuthState = {
  isLoading: false,
  isAutoLogging: true,
  error: null,
  userMe: null,
};
// ----------------------------------------------------------------------------
// ----------------------------------------------------------------------------
export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      localStorage.removeItem('token');
      state.userMe = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(loginAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(loginAsync.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.userMe = payload;
      })
      .addCase(loginAsync.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload as string;
      });

    builder
      .addCase(autoLoginAsync.pending, (state, { payload }) => {
        state.isAutoLogging = true;
      })
      .addCase(autoLoginAsync.fulfilled, (state, { payload }) => {
        state.isAutoLogging = false;
        state.userMe = payload;
      })
      .addCase(autoLoginAsync.rejected, (state) => {
        state.isAutoLogging = false;
      });


  },
});

export const { logout } = authSlice.actions;

export const authReducer = authSlice.reducer;
