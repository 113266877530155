import { useMemo } from 'react'
import { SlideView } from '../../../types/SlideView'
import { VideoSlide } from '../../../types/slide'
import { FILES_BASE_URL } from '../../../constants/FILES_BASE_URL'
import { css } from '@emotion/css'

const SlideViewVideo: SlideView<VideoSlide> = ({ slide, filesHashes }) => {


  const videoUrl = useMemo(() => {
    const videoFileHashObject = filesHashes.find(
      (fileHash) => fileHash.id === slide.videoFileId
    ) || (
      // @ts-ignore
      slide.videoFile
    )

    if (!videoFileHashObject) {
      // console.error(`File hash for videoFileId ${slide.videoFileId} not found`)
      return ''
    }

    return `${FILES_BASE_URL}/${videoFileHashObject.fileHash}`
  }, [slide.videoFileId, filesHashes])

  return (

    <video className={styles.root} controls>
      <source src={videoUrl} type="video/mp4" />
    </video>
  )
}

const styles = {
  root: css({
    maxWidth: '100%',
    margin: '0 auto',
    display: 'block',
    userSelect: 'none',
  }),
}

export default SlideViewVideo
