/** @jsxImportSource @emotion/react */

import { useState, useMemo } from 'react'
import {
  useDeleteUserAvatarMutation,
  useGetUserAvatarsQuery,
} from '../../store/userAvatarsApi'
import LoadingPage from '../LoadingPage'
import { Button, Checkbox, Container, FormControlLabel, Paper, Stack, LinearProgress } from '@mui/material'
import { BgiLink } from '../../UI/BgiLink'
import UserAvatar from './UserAvatar'
import { css } from '@emotion/react'
import { useTranslation } from 'react-i18next'
import UseDynamicLanguageStyle from '../i18n/UseDynamicLanguageStyle'
import Layout from '../page/Layout'
import UserAvatarForm from './UserAvatarForm'
import { FormProvider, useForm } from 'react-hook-form'


const UserAvatarPage = () => {
  const { isLoading: isGetting, isFetching, error, data: userAvatars } = useGetUserAvatarsQuery()

  // const { t } = useTranslation(['UserAvatar', 'common'])
  const { dynamicLanguageStyle } = UseDynamicLanguageStyle()

  const [isOpen, setIsOpen] = useState(false)
  const [isSending, setIsSending] = useState(false)

  const userAvatarForm = useForm();

  if (isGetting) return <LoadingPage />

  // ----------------------------------------------------------------
  return (
    <Layout>
      <div css={dynamicLanguageStyle}>
        <Container maxWidth="md">
          <BgiLink to={'/'}>{('common:Back_to_the_home_page')}</BgiLink>


          <Button variant="outlined" onClick={() => setIsOpen(true)}>
            New Avatar
          </Button>


          <FormProvider {...userAvatarForm}>
            <UserAvatarForm
              isSending={isSending}
              setIsSending={setIsSending}
              isOpen={isOpen}
              onOpen={() => setIsOpen(true)}
              onClose={() => setIsOpen(false)}
            />
          </FormProvider>

          <div style={{ width: '100%', height: 30 }} />

          {error || !userAvatars ? (
            <p>Unknown Error occurred</p>
          ) : (
            <div css={{
              display: 'grid',
              gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
              gap: 12,
            }}>
              {userAvatars?.map((userAvatar) => (
                <UserAvatar key={userAvatar.id} userAvatar={userAvatar} />
              ))}
            </div>
          )}

          {isFetching && (

            <LinearProgress style={{
              position: 'fixed',
              top: 0,
              left: 0,
              height: 4,
              width: '100%',
              zIndex: 5
            }} />

          )}

          <div style={{ width: '100%', height: 30 }} />
        </Container>
      </div>
    </Layout>
  )
}

export default UserAvatarPage
