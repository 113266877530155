import { useState, ReactNode } from 'react'
import { useForm, SubmitHandler, useWatch, FormProvider } from 'react-hook-form'
import {
  Container,
  Stack,
  Button,
  Paper,
  Typography,
  Alert,
  CircularProgress,
} from '@mui/material'
import { axiosInstance } from '../../utils/axiosInstance'
import { BgiTextField } from '../../UI/BgiTextField'
import countries from '../../data/countries'
import BgiAutocomplete from '../../UI/BgiAutocomplete'
import BgiRadioGroup from '../../UI/BgiRadioGroup'
import { BgiToggleButtonGroup } from '../../UI/BgiToggleButtonGroup'
import BgiInputsList from '../../UI/BgiInputsList'
import { buildGetErrMsg } from '../../utils/buildGetErrMsg'
import useSnackResErr from '../../hooks/useSnackResErr'
import Layout from '../page/Layout'
import { useTranslation } from 'react-i18next'

interface Inputs {
  email: string
  password: string
  repeatPassword: string
  name: string
  lastName: string
  gender: string
  school: string
  classLevel: string
  city: string
  country: {
    code: string
    name: string
  }
  schoolType: string
  mobile: string
  whatsapp: string
  role: 'student' | 'teacher'
}

const getErrMsg = buildGetErrMsg({
  USER_EXISTS: 'There is already an registered account with this email',
})
// --comp--------------------------------------------------------------------------
// --------------------------------------------------------------------------------
// --------------------------------------------------------------------------------
export default function SignUpForOther() {
  const formContext = useForm<Inputs>({
    defaultValues: {
      whatsapp: '00',
      mobile: '00',
      schoolType: 'stateSchool',
      gender: 'male',
      country: { code: 'YE', name: 'Yemen' },
    }
  })
  const { handleSubmit, control, getValues } = formContext
  const [isPassNotMatch, setIsPassNotMatch] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isRegistering, setIsRegistering] = useState(false)

  const role = useWatch({ control, name: 'role' })

  const { t } = useTranslation('Signup_login')

  const snackResErr = useSnackResErr(getErrMsg)

  const [curStep, setStep] = useState<number>(0)
  const FINAL_STEP = 2

  const nextStep = () =>
    setStep((prevVal) => {
      const resultStep = prevVal + 1
      return resultStep <= FINAL_STEP ? resultStep : FINAL_STEP
    })
  const backStep = () =>
    setStep((prevVal) => {
      const resultStep = prevVal - 1
      return resultStep >= 0 ? resultStep : 0
    })

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    formContext.clearErrors();

    // ---validator----------------------------------------------------------
    if (
      !data.email.includes('.') ||
      /(gmil|gmali|gmial|gamil |gmai|jmail|yaho|hotmial|outllok|oullok|outook|outlok|,)\.[a-z]+$/.test(data.email) ||
      /\.(cm|cmo|comm|ccm)$/.test(data.email)
    ) {
      formContext.setError('email', { message: 'يرجى التأكد من صحة كتابة الإيميل' });
      return;
    }
    // ----------------------------------------------------------validator---
    if (data.password !== data.repeatPassword) {
      return setIsPassNotMatch(true)
    } else {
      setIsPassNotMatch(false)
    }

    if (curStep !== FINAL_STEP) return nextStep()

    const { repeatPassword, country, ...rest } = data

    const signupData = {
      ...rest,
      countryCode: country.code,
    }

    setIsRegistering(true)

    try {
      await axiosInstance.post('/auth/signup', signupData)
      setIsSuccess(true)
    } catch (e: any) {
      snackResErr(e)
    } finally {
      setIsRegistering(false)
    }
  }
  // -----------------------------------------------------------------------------onSubmit---

  let stepView: ReactNode

  if (curStep === 0) {
    stepView = (
      <>
        <BgiTextField label={t('Enter_your_email')} name="email" type='email' required />

        <BgiTextField
          label={t('Password')}
          type="password"
          name="password"
          required
          minLength={6}
          maxLength={128}
        />

        <div>
          <BgiTextField
            label={t('Repeat_password')}
            type="password"
            name="repeatPassword"
            required
            minLength={6}
            maxLength={128}
          />

          {isPassNotMatch && (
            <Typography
              variant="body1"
              style={{ color: '#f44336', marginTop: 2 }}
            >
              {t('The_passwords_do_not_match')}
            </Typography>
          )}
        </div>
      </>
    )
  } else if (curStep === 1) {
    stepView = (
      <>
        <BgiTextField
          label={t('Name')}
          name="name"
          required
          minLength={2}
          maxLength={32}
          dir='rtl'
        />

        <BgiTextField
          label={t('Last_name')}
          name="lastName"
          required
          minLength={2}
          maxLength={32}
          dir='rtl'
        />

        {/* <BgiRadioGroup
          label={t('Gender')}
          name="gender"
          options={[
            { label: t('Male'), value: 'male' },
            { label: t('Female'), value: 'female' },
          ]}
          required
        /> */}



        {/* <BgiTextField label={t('City')} name="city" required placeholder={t('City_free')} />
        <BgiTextField label={t('Mobile')} name="mobile" type='tel' /> */}
        {/* <BgiTextField label={t('Whatsapp')} name="whatsapp" /> */}
        <p style={{ fontSize: '12px', direction: 'rtl' }}>{t('Write_your_whatsApp_number_if_you_agree_to_be_contacted_via_whatsApp_in_case_of_non_registration_or_inquiries')}</p>
        <p style={{ fontSize: '12px', direction: 'rtl' }}>{t('If_you_are_experiencing_problems_with_the_registration_process_you_can_ask_for_help_from_other_students_in_the_WhatsApp_group_according_to_your_class')}</p>
        <p style={{ fontSize: '12px', direction: 'rtl' }}>{t('WhatsApp_links_for_the_groups_can_be_found_on_the_website_interface')}</p>

        <BgiAutocomplete
          label={t('Country')}
          name="country"
          size="small"
          options={countries}
          groupBy={(country) => country.name[0]}
          getOptionLabel={(option) => option.name}
          fullWidth
          required
          isOptionEqualToValue={(option, value) => option.code === value.code}
        />

        <span style={{ display: 'none' }}>
          <BgiToggleButtonGroup
            label={t('Account_type')}
            name="role"
            defaultValue="student"
            options={[
              { label: t('Student'), value: 'student' },
              { label: t('Teacher'), value: 'teacher', disabled: true },

            ]}
            required
          />
        </span>
      </>
    )
  } else if (curStep === 2) {
    stepView =

      <>
        <BgiTextField label={t('Organization_name')} name="school" />
        <BgiTextField label={t('Job')} name="classLevel" placeholder={t('Class_level_free')} />
      </>

  }

  return (
    <Layout>
      <Container maxWidth="sm">
        <div style={{ height: 30 }} />

        <Paper style={{ padding: '1rem' }} elevation={3}>
          {isSuccess ? (
            <Alert variant="filled" color="success">
              <p style={{ marginBottom: '0.5rem' }}>
                {t('The_verification_Link_has_been_sent_to_your_email')}
                {getValues().email}"
              </p>
              <br />
              <p>{t('Please_check_your_primary_inbox_spam_etc')}</p>
              <p>{t('The_confirmation_message_has_been_sent_to_your_email_please_confirm_the_subscription')}</p>
              <p>{t('If_you_do_not_find_the_message_in_the_inbox_search_for_it_in_the_Junk_Email_spam')}</p>
              <p>{t('You_can_watch_the_following_movie_to_learn_how_to_confirm')}</p>
              <div>
                <div>
                  <iframe
                    width="100%"
                    height="315"
                    src="https://www.youtube.com/embed/Mi_oY4m2coc?si=WYpu22B5AmX4AJwg"
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                    rel="0"
                  >
                  </iframe>
                </div>

              </div>

            </Alert>
          ) : (
            <>
              <Typography
                style={{
                  textAlign: 'center',
                  marginBottom: '1rem',
                  fontWeight: 700,
                }}
                variant="h5"
              >
                Sign Up
              </Typography>

              <FormProvider {...formContext}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Stack spacing={2} key={String(curStep)}>
                    {stepView}

                    {curStep > 0 && (
                      <Button type="button" onClick={backStep} variant="outlined">
                        Back
                      </Button>
                    )}

                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      startIcon={isRegistering && <CircularProgress size={20} />}
                      disabled={isRegistering}
                    >
                      {curStep === FINAL_STEP ? t('Send') : t('Next_step')}
                    </Button>
                  </Stack>
                </form>
              </FormProvider>
            </>
          )}
        </Paper>
        <div style={{ height: '25rem' }} />
      </Container>
    </Layout>
  )
}
